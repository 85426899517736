import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'

import Content from '../components/Content'
import MarkdownContent from '../components/MarkdownContent'

import HorizontalDivider from '../components/HorizontalDivider'

import Button from '../components/Button'
import Helmet from 'react-helmet'

// eslint-disable-next-line
export const ProcessPageTemplate = ({
  title,
  description,
  secondInformation,
  bulletedList,
  contentComponent,
  siteTitle,
}) => {
  const PageContent = contentComponent || Content

  return (
    <section>
      <div className="container flex flex-col">
        <Helmet>
          <title>{`${title} | ${siteTitle || 'Content Manager'}`}</title>
          <meta
            name="description"
            content={`Our style combines interactive, engaged, positive coaching with the structural depth of therapy to give you the best of both approaches.`}
          />
          <meta property="og:title" content={`${title} | ${siteTitle}`} />
          <meta
            property="og:description"
            content={`Our style combines interactive, engaged, positive coaching with the structural depth of therapy to give you the best of both approaches.`}
          />
        </Helmet>
        <h1 className="mt-10 mb-8 text-4xl purple-text text-purple font-semibold font-playfair md:mt-20 md:text-5xl md:mb-18 lg:text-7xl lg:mb-24">
          {title}
        </h1>
        <div className="flex flex-col mb-2">
          <PageContent className="content text-left" content={description} />
          <div className="mb-8 mt-8 flex justify-center md:justify-start md:mt-12 md:mb-10">
            <Link className="w-fit self-center" to="/services">
              <Button name="Services" />
            </Link>
          </div>
        </div>
        <HorizontalDivider />
        <div className="flex flex-col">
          <h3 className="mb-4 lg:mt-10">{secondInformation.title}</h3>
          <p className="md:text-xl">{secondInformation.description}</p>
          <div className="flex flex-col border-2 blue-border border-blue items-center mt-8 mb-4 py-8 px-4 md:w-1/2 md:self-center lg:w-2/3 lg:mt-20 lg:hidden">
            {bulletedList &&
              bulletedList.map((item) => (
                <a
                  href={item.link}
                  className="text-center mb-2 font-medium w-fit text-xl lg:font-light lg:mb-8"
                  rel="noopener noreferrer"
                  target="_blank"
                  key={item.link}
                >
                  {' '}
                  {item.text}
                </a>
              ))}
          </div>
          <div className="hidden grid grid-cols-3 gap-8 border-2 blue-border border-blue mt-8 mb-4 py-8 px-4 md:w-1/2 md:self-center lg:w-10/12 lg:mt-20 lg:grid lg:px-8">
            {bulletedList &&
              bulletedList.map((item) => (
                <div className="w-100">
                  <a
                    href={item.link}
                    className="text-center mb-2 font-medium w-fit text-xl lg:font-light lg:mb-8"
                    rel="noopener noreferrer"
                    target="_blank"
                    key={item.link}
                  >
                    {' '}
                    {item.text}
                  </a>
                </div>
              ))}
          </div>
          <div className="mb-2 mt-8 flex justify-center md:mt-8 md:mb-8 lg:mb-8 lg:mt-10">
            <Link className="w-fit self-center" to="/contact">
              <Button name="Get In Touch" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

ProcessPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  secondInformation: PropTypes.object.isRequired,
  bulletedList: PropTypes.array.isRequired,
  contentComponent: PropTypes.func,
}

const ProcessPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <ProcessPageTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        secondInformation={frontmatter.secondInformation}
        bulletedList={frontmatter.bulletedList}
        contentComponent={MarkdownContent}
        siteTitle={data.site.siteMetadata.title}
      />
    </Layout>
  )
}

ProcessPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProcessPage

export const ProcessPageQuery = graphql`
  query ProcessPage($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        secondInformation {
          title
          description
        }
        bulletedList {
          text
          link
        }
      }
    }
  }
`
